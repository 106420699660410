import { config } from "../../js/config";
import { Api } from "../../service/Api";

const state = () => ({
  info: {
    id: 1,
  },
});

const getters = {};

const actions = {
  getUserInfoToState({ commit }, data) {
    new Api().getUserInfo(data).then((res) => {});
  },
  setUserInfoToState({ commit }, data) {
    commit("setUserInfoMutation", data);
  },
  getUserInfo({ commit }, data) {
    return new Api().getUserInfo(data);
  },
  getUserList({ commit }, data = {}) {
    return new Api().getList(config.getUserList, data);
  },
  setUserBan({ commit }, data = {}) {
    return new Api().getList(config.setUserBan, data);
  },
  setUserStatus({ commit }, data) {
    return new Api().setStatus("User", data.id, data.status);
  },
  getUserSocialList({ commit }, data = {}) {
    return new Api().getList(config.getUserSocialList, data);
  },
  showComments({ commit }, data = {}) {
    return new Api().getList(config.showComments, data);
  },
  socialComment({ commit }, data = {}) {
    return new Api().getList(config.socialComment, data);
  },
  replyComment({ commit }, data = {}) {
    return new Api().getList(config.replyComment, data);
  },
  getUserSocialDetail({ commit }, data = {}) {
    return new Api().getList(config.getUserSocialDetail, data);
  },
  getUserSocialCommentList({ commit }, data = {}) {
    return new Api().getList(config.getUserSocialCommentList, data);
  },
  setUserCredit({ commit }, data = {}) {
    return new Api().edit(config.setUserCredit, data);
  },
  setUserRole({ commit }, data = {}) {
    return new Api().edit(config.setUserRole, data);
  },
  setUserSocialStatus({ commit }, data) {
    return new Api().setStatus("SocialModel", data.id, data.status);
  },
  socialVerify({ commit }, data) {
    return new Api().edit(config.socialVerify, data);
  },
  //赠送用户vip
  donateVip({ commit }, data) {
    return new Api().edit(config.donateVip, data);
  },
  //获取用户vip记录
  getUserVipLog({ commit }, data) {
    return new Api().getList(config.getUserVipLog, data);
  },
  //关闭vip
  closeUserVip({ commit }, data) {
    return new Api().getList(config.closeUserVip, data);
  },
  //获取VIP
  getUserVipList({ commit }, data) {
    return new Api().getList(config.getUserVipList, data);
  },
  // 会员列表 - 关闭会员
  closeVip({ commit }, id) {
    return new Api().del(config.closeVip,id);
  },
  giveVip({ commit }, data) {
    return new Api().getList(config.giveVip, data);
  },

  transferList({ commit }, data) {
    return new Api().getList(config.transferList, data);
  },
  transferDetail({ commit }, data) {
    return new Api().getList(config.transferDetail, data);
  },
  noticeList({ commit }, data) {
    return new Api().getList(config.noticeList, data);
  },
  selectStudents({ commit }, data) {
    return new Api().getList(config.selectStudents, data);
  },
  publish({ commit }, data) {
    return new Api().getList(config.publish, data);
  },
  noticeDetail({ commit }, data) {
    return new Api().getList(config.noticeDetail, data);
  },
  noticeEdit({ commit }, data) {
    return new Api().getList(config.noticeEdit, data);
  },
};

const mutations = {
  setUserInfoMutation(state, data) {
    state.info = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
